.Pack{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(10, 9, 8, 0.60);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    z-index: 99999;
    color: #fff;
    .popUps{
        width: 540px;
        border-radius: 20px;
        border: 1px solid var(--Stroke-soil-40, rgba(223, 195, 145, 0.40));
        background: #0A0908;
        padding: 40px;
        text-align: center;
        h2{
            font-size: 20px;
            line-height: 20px;
        }
        ul{
            margin: 40px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            li{
                margin: 0 10px;
                img{
                    width: 70px;
                }
            }
        }
        .inputPack{
            width: 100%;
            input{
                outline-style: none ;
                border: 0; 
                border-radius: 8px;
                padding: 6px;
                width: 300px;
                font-size: 18px;
                width: 100%;
                height: 48px;
                text-align: center;
                background: #232221;
                color: #F5EDDE;
            }
            input:focus{
                border-color: #66afe9;
                outline: 0;
                -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(233, 207, 102, 0.6);
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(233, 207, 102, 0.6)
            }
            p{
                font-size: 14px;
                font-weight: 800;
                margin-top: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 20px;
                    margin-right: 8px;
                }
            }
        }
        .success{
            input{
                border: 1px solid #5BD493; 
                background: #5bd49326;
            }
            p{
                color: #5BD493;
            }
        }
        .failed{
            input{
                border: 1px solid #E2294F; 
                background: #e2294e2a;
            }
            p{
                color: #E2294F;
            }
        }
        .buttonPack{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 50px;
            div{
                width: 224px;
                font-size: 18px;
                font-weight: 900;
                line-height: 44px;
                border-radius: 8px;
                letter-spacing: 1.8px;
                cursor: pointer;
            }
            .closeButton{
                background: #5F5F5F;
                color: #CECECE;
            }
            .confirmButton{
                background: #DFC391;
                color: #000;
                cursor: not-allowed;
                opacity: 0.2;
                &.success{
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }
}
@media (max-width: 600px) {
    .Pack{
        .popUps{
            width: 3.24rem;
            h2{
                font-size: 0.2rem;
                line-height: 0.2rem;
            }
            ul{
                margin: 0.4 0;
                li{
                    margin: 0 0.1rem;
                    img{
                        width: 0.7rem;
                    }
                }
            }
            .inputPack{
                input{
                    border-radius: 0.08rem;
                    padding: 0;
                    width: 2.44rem;
                    font-size: 0.16rem;
                    height: 0.48rem;
                }
                input:focus{
                    border-color: #66afe9;
                    outline: 0;
                    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(233, 207, 102, 0.6);
                    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(233, 207, 102, 0.6)
                }
                p{
                    font-size: 0.14rem;
                    margin-top: 0.06rem;
                    img{
                        width: 0.2rem;
                        margin-right: 0.08rem;
                    }
                }
            }
            .buttonPack{
                margin-top: 0.5rem;
                div{
                    width: 1.16rem;
                    font-size: 0.18rem;
                    line-height: 0.44rem;
                    border-radius: 0.08rem;
                }
            }
        }
    }
}