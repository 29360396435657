.Pack{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #707894;
    z-index: 999999;
    .popUps{
        width: 520px;
        border-radius: 40px;
        border: 5px solid var(--Linear, #6B93F2);
        box-shadow: 6px 6px 0px 0px #6844CC;
        background: #fff;
        padding: 40px 48px;
        text-align: center;
        h2{
            font-size: 20px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img{
                width: 153px;
            }
            .decorateh5{
                display: none;
            }
        }
        p{
            margin: 24px 0 20px 0;
        }

        .buttonPack{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            div{
                width: 200px;
                font-size: 18px;
                font-weight: 900;
                line-height: 44px;
                border-radius: 100px;
                letter-spacing: 1.8px;
                background: #FFF;
                cursor: pointer;
            }
            .closeButton{
                border: 2px solid #ACACAC;
                color: #ACACAC;
            }
            .confirmButton{
                border: 2px solid #3860D0;
                color: #3860D0;
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 600px) {
    .Pack{
        .popUps{
            width: 3.24rem;
            padding: 0.4rem 0.2rem;
            h2{
                font-size: 0.16rem;
                line-height: 0.2rem;
                img{
                    width: 0.81rem;
                    display: none;
                }
                .decorateh5{
                    display: block;
                }
            }
            .buttonPack{
                margin-top: 0.5rem;
                flex-direction: column;
                margin-top: 0.16rem;
                div{
                    width: 100%;
                    font-size: 0.18rem;
                    line-height: 0.44rem;
                    border-radius: 0.4rem;
                    margin-top: 0.12rem;
                }
            }
        }
    }
}