.headerBox{
    width: 1440px;
    height: 100px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 140px 0 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #707894;
    z-index: 9999;
    border-radius: 0 0 60px 60px;
    img{
        width: auto;
        height: auto;
    }
    .headerL{
        width: 152px;
        font-size: 0;
        img{
            width: 100%;
        }
    }
    .headerR{
        display: flex;
        align-items: center;
        padding-right: 20px;
        i{
            width: 1px;
            height: 20px;
            background: #6D8FF0;
            margin: 0 8px;
        }
        .options{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 140px;
            height: 100px;
            font-size: 16px;
            font-weight: 900;
            cursor: pointer;
            position: relative;
            &.selected{
                border-bottom: 3px solid var(--Linear, #A647EA);
            }
            img{
                width: 24px;
                // height: 24px;
            }
            .connectListPack{
                // overflow: hidden;
                display: none;
                z-index: 99999;
                position: absolute;
                top: 100px;
                left: 0;
                ul{
                    width: 155px;
                    background: #fff;
                    li{
                        display: flex;
                        align-items: center;
                        color: #A647EA;
                        font-size: 16px;
                        height: 48px;
                        padding-left: 24px;
                        position: relative;
                        img{
                            width: 24px;
                            margin-right: 8px;
                        }
                        .hovPack{
                            position: absolute;
                            right: -155px;
                            top: 0;
                            width: 155px;
                            background: #fff;
                            display: none;
                            div{
                                height: 40px;
                                line-height: 40px;
                                color: #A647EA;
                                font-size: 16px;
                                text-align: center;
                            }
                            div:hover{
                                background: var(--Linear, linear-gradient(90deg, #A647EA 0%, #BD75F3 43.5%, #DAB0FF 100%));
                                color: #fff;
                            }
                        }
                    }
                    li:hover{
                        background: var(--Linear, linear-gradient(90deg, #A647EA 0%, #BD75F3 43.5%, #DAB0FF 100%));
                        color: #fff;
                    }
                    li:hover .hovPack{
                        display: block;
                    }
                }
            }
           
        }
        .options:hover .connectListPack{
            display: block;
        }
        
    }
    .buyButton {
        width: 200px;
        height: 60px;
        border-radius: 40px;
        border: 2px solid #6E94F5;
        background: #FFF;
        text-align: center;
        line-height: 60px;
        color: #6E94F5;
        font-family: "Source Han Serif TC";
        font-size: 20px;
        font-weight: 700;
        margin-right: 12px;
        cursor: pointer;
        .buyButtonPopup {
            top: 100px;
            padding: 5px;
            width: 456px;
            height: 150px;
            box-sizing: border-box;
            position: absolute;
            background-color: #FFF;
            display: none;
            .borderBg{
                height: 100%;
                border: 2px solid #AD57ED;
                background-image: url('/img/downLeftBorderBg.png'), url('/img/downRightBorderBg.png');
                background-position: left top, right bottom;
                background-repeat: no-repeat;
                background-size: 75px 75px;
                display: flex;
                align-items: center;
                color: #a74beb;
                font-size: 14px;
                font-weight: 700;
                padding: 0 32px 0 35px;
            }
            .qrCode {
                width: 100px;
                height: 100px;
                padding: 1px;
                border: 6px solid #707894;
            }
        }
     }
    .downButton{
        cursor: pointer;
        width: 240px;
        height: 60px;
        border-radius: 100px;
        background: #D42E54;
        box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.15), -3px -5px 2px 0px rgba(0, 0, 0, 0.20) inset, 1px 2px 2px 0px rgba(255, 255, 255, 0.40);
        font-size: 20px;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        position: relative;
        img{
            width: 24px;
            margin: 0 5px;
        }
        .iconR{
            transform: rotateY(180deg);
        }

    }
    .buyButton:hover {
        .buyButtonPopup {
            display: block;
        }
    }
}
// .buyButton{
//     text-align: center;
//     color: #000;
//     font-size: 18px;
//     font-weight: 900;
//     background: linear-gradient(180deg, #D6BB8B 0%, #F8E7C8 25%, #DFC391 70%, #86775D 100%);
//     cursor: pointer;
// }
.headerH5{
    display: none;
}

@media (max-width: 600px) {
    .headerBox{
        display: none;
    }
    .booth{
        height: 0.6rem;
    }
    .headerH5{
        width: 100%;
        height: 0.6rem;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.28rem;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        img{
            height: auto;
            width: auto;
        }
        .headerL{
            width: 0.94rem;
            font-size: 0;
            img{
                width: 100%;
            }
        }
        .headerR{
            display: flex;
            align-items: center;
            .buyButton {
                cursor: pointer;
                width: .7rem;
                height: 0.4rem;
                border-radius: 1rem;
                background: #FFF;
                font-size: 0.14rem;
                color: #6e94f5;
                display: flex;
                align-items: center;
                justify-content: center;
                border: .01rem solid #6e94f5;
                margin-right: .08rem;
            }
            .downButton{
                cursor: pointer;
                width: .9rem;
                height: 0.4rem;
                border-radius: 1rem;
                background: #D42E54;
                box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.15), -3px -5px 2px 0px rgba(0, 0, 0, 0.20) inset, 1px 2px 2px 0px rgba(255, 255, 255, 0.40) inset;
                font-size: 0.14rem;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.15rem;
            }
            .downButtonPopup {
                position: fixed;
                width: 2.8rem;
                padding: .4rem .6rem;
                display: flex;
                flex-flow: column;
                align-items: center;
                border-radius: 40px;
                border: 0.05rem solid #A647EA;
                background: #FFF;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .downDesc {
                    font-size: .15rem;
                    color: #707894;
                    margin: .24rem 0 .2rem;
                }
                .downPg {
                    width: 2.4rem;
                    text-align: center;
                    color: #3860d0;
                    font-size: .18rem;
                    background-image: url('/img/downLeftBg.png'), url('/img/downRightBg.png');
                    background-position: left center, right center;
                    background-repeat: no-repeat;
                    background-size: .8rem .2rem;
                }
                .popupDownbutton {
                    width: 2.4rem;
                    height: .4rem;
                    line-height: .4rem;
                    border-radius: 4rem;
                    background: #D42E54;
                    font-size: .14rem;
                    font-weight: 700;
                    text-align: center;
                    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.15), -3px -5px 2px 0px rgba(0, 0, 0, 0.20) inset, 1px 2px 2px 0px rgba(255, 255, 255, 0.40) inset;
                }
            }
            .headerSwitch{
                width: 0.28rem;
                font-size: 0;
                img{
                    width: 100%;
                }
            }
        }
        .bgPack{
            position: absolute;
            top: 0.6rem;
            left: 0;
            right: 0;
            height: calc(100vh - 0.6rem);
            background: rgba(255, 255, 255, 0.70);
        }
        .menuPack{
            width: 1.88rem;
            height: calc(100vh - 0.6rem);
            background: #fff;
            position: absolute;
            right: 0;
            top: 0.6rem;
            z-index: 999;
            padding: 0.4rem 0.2rem;
            .options{
                width: 1.8rem;
                line-height: 0.4rem;
                font-size: 0.16rem;
                color: #707894;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 0.28rem;
                position: relative;
                padding-left: 0.15rem;
                .optionsImg{
                    width: 0.24rem;
                    margin-right: 0.06rem;
                    
                }
                
            }
            .connectListPack{
                margin-top: -0.2rem;
                ul{
                    border-radius: 0.2rem;
                    padding: 0 12px;
                    position: relative;
                    z-index: 100;
                    li{
                        display: flex;
                        align-items: center;
                        color: rgb(59, 59, 59);
                        font-size: 0.16rem;
                        height: 0.5rem;
                        border-radius: 6px;
                        position: relative;
                        img{
                            width: 0.24rem;
                            margin-right: 0.08rem;
                        }
                        .hovPack{
                            position: absolute;
                            left: -2.16rem;
                            top: 0;
                            width: 1.87rem;
                            background: #fff;
                            // display: none;
                            div{
                                height: 0.4rem;
                                line-height: 0.4rem;
                                color: #A647EA;
                                font-size: 0.16rem;
                                text-align: center;
                            }
                            div:hover{
                                background: var(--Linear, linear-gradient(90deg, #A647EA 0%, #BD75F3 43.5%, #DAB0FF 100%));
                                color: #fff;
                            }
                        }
                    }
                    // li:hover .hovPack{
                    //     display: block;
                    // }
                }
            }
            .buyButton{
                border-radius: 0.08rem;
                width: 1.6rem;
                line-height: 0.48rem;
                position: absolute;
                bottom: 0.19rem;
                left: 0.3rem;
            }
        }
    }
}